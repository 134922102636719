import React, { Component } from 'react';

export default class Header extends Component {
  constructor(props){
    super(props);
    this.handleCallback = this.handleCallback.bind(this)
  }

  handleCallback(event)
  {
    this.props.onLangChange(event.target.value);
  }
  render() {

    let resumeData = this.props.resumeData;

    return (
      <React.Fragment>
      <header id="home">
         <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
               <li className="current"><a className="smoothscroll" href="#home">{resumeData.homeTitle}</a></li>
               <li><a className="smoothscroll" href="#about">{resumeData.aboutTitle}</a></li>
               <li><a className="smoothscroll" href="#resume">{resumeData.experienceTitle}</a></li>
               <li><a className="smoothscroll" href="#portfolio">{resumeData.workTitle}</a></li>
               <div className="dropdown">
                  <button className="dropbtn">{resumeData.languages}</button>
                  <div className="dropdown-content">
                      <button type="button" onClick={this.handleCallback} value="es">Español</button>
                      <button type="button" onClick={this.handleCallback} value="ca">Català</button>
                      <button type="button" onClick={this.handleCallback} value="no">Norsk Bokmål</button>
                      <button type="button" onClick={this.handleCallback} value="en">English</button>
                    </div>
                </div>
            </ul>

         </nav>
         <div className="row banner">
            <div className="banner-text">
               <h1 className="responsive-headline">{resumeData.name}.</h1>
               <h2 style={{color:'#fff'}}>{resumeData.role}</h2>
               <h3 style={{color:'#fff'}}>{resumeData.role} {resumeData.roleDescription}
               </h3>
               <ul className="social">
                  {
                    resumeData.socialLinks && resumeData.socialLinks.map(item =>{
                      return(
                              <li key={item.name}>
                                <a href={item.url} target="_blank"><i className={item.className}></i></a>
                              </li>
                            )
                          }
                    )
                  }
               </ul>
            </div>
         </div>

         <p className="scrolldown">
            <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
         </p>

      </header>
      </React.Fragment>
    );
  }
}