import React, { Component } from 'react';
import Header from './components/Header';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import resumeData from './resumeData';

class App extends Component {

  state = {
    lang: "",
    resumeData: resumeData
  }

  handleCallback  = (langSelected) => {

    if(langSelected === "es")
    {
      this.setState(resumeData  =  {
        "homeTitle": "Inicio",
        "languages": "IDIOMAS",
        "aboutTitle": "Sobre mí",
        "workTitle": "Trabajos",
        "educationTitle": "Educación",
        "experienceTitle": "Experiencia",
        "contactTitle": "Contacto",
        "name": "Daniel Soligó García",
        "role": "Full-Stack developer",
        "linkedinId":"daniel-soligó-garcía-8276aa120",
        "skypeid": "Your skypeid",
        "roleDescription": "experimentado especialmente en backend, pero creciendo en frontend. Actualmente estoy trabajando en Ohtic como as Full-Stack developer, mis tareas son desarrollar nuevas funcionalidades, realizar mantenimiento de múltiples aplicaciones, hacer analisis funcional y técnico y migración de aplicaciones a tecnologías mas actuales. Trabajo con las siguientes tecnologias: VB, ASP.NET, C#, .NET CORE, .NET Framework, SQL, React.",
        "socialLinks":[
            {
              "name":"linkedin",
              "url":"https://www.linkedin.com/in/daniel-soligó-garcía-8276aa120/",
              "className":"fa fa-linkedin"
            },
            {
              "name":"github",
              "url":"https://github.com/soligo94",
              "className":"fa fa-github"
            },
          ],
        "aboutmeTitle":"Sobre mí",
        "contactDetailsTitle":"Detalles de contacto",
        "aboutme":"Soy una persona muy curiosa, me gusta aprender nuevas tecnologias y crecer como software developer. Hablo 4 idiomas: Español, Catalán, Inglés y Noruego. En mi tiempo libre juego a videojuegos con mis amigos, salir con ellos y pasear por la naturaleza.",
        "address":"soligodevelopment@outlook.es",
        "education":[
          {
            "UniversityName":"Centre d'estudis politècnics de Barcelona",
            "specialization":"Desarrollo de aplicaciones multiplataforma",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2017",
            "Achievements":"Grado superior de desarrollo de aplicaciones multiplataforma, donde aprendí a programar y a usar lenguajes como: Java, HTML, CSS, C#, C y desarrollo de aplicaciones móviles con Java."
          },
          {
            "UniversityName":"Taulé viñas",
            "specialization":"Gestión administrativa",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2013",
            "Achievements":"Grado medio en gestión administrativa."
          }
        ],
        "work":[
          {
            "CompanyName":"Ohtic",
            "specialization":"Full-Stack developer",
            "MonthOfLeaving":"Actualidad",
            "YearOfLeaving":"",
            "Achievements":"Desarrollo Full-Stack con ASP.NET, React, VB, C#, .NetCore, .NetFramework, SQL y TypeScript"
          },
          {
            "CompanyName":"Nunegal Consulting",
            "specialization":"C# developer",
            "MonthOfLeaving":"Mar",
            "YearOfLeaving":"2023",
            "Achievements":"Desarrollo de backend con C#."
          },
          {
            "CompanyName":"Itequia",
            "specialization":"Full-Stack developer",
            "MonthOfLeaving":"Dec",
            "YearOfLeaving":"2022",
            "Achievements":"Desarrollo full-stack en diversos proyectos con las siguientes tecnologias: C#, ASP.NET, .NET Framework, .NET Core, React y WPF."
          },
          {
            "CompanyName":"ERNI",
            "specialization":"Software developer",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2022",
            "Achievements":"Desarrollo y mantenimiento de aplicaciones en diversos proyectos con las siguientes tecnologias: C#, Cache object script, WPF, Oracle, Java, SQL, JS."
          },
          {
            "CompanyName":"Consultia",
            "specialization":"Software developer",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2018",
            "Achievements":"Mantenimiento de aplicaciones en diversos proyectos con las siguientes tecnologias: C#, WinForms, Oracle."
          },
        ],
        "skillsDescription":"Estas son las habilidades que dispongo en las siguientes tecnologias, también tienes mi github donde puedes ver más",
        "skills":[
          {
            "skillname":"CSharp"
          },
          {
            "skillname":"VB"
          },
          {
            "skillname":"NetCore"
          },
          {
            "skillname":"NetFramework"
          },
          {
            "skillname":"WPF"
          },
          {
            "skillname":"SQLServer"
          }
          ,
          {
            "skillname":"React"
          }
          ,
          {
            "skillname":"JavaScript"
          }
          ,
          {
            "skillname":"TypeScript"
          }
        ],
        "portfolioTitle":"Aqui tienes algunos de mis proyectos realizados",
        "portfolio":[
          {
            "name":"La llar del vidre",
            "description":"Página web",
            "imgurl":"images/portfolio/Llardelvidre.png",
            "url":"http://www.lallardelvidre.com/"
          },
          {
            "name":"Psicomilenial",
            "description":"Página web",
            "imgurl":"images/portfolio/psicomilenial.png",
            "url":"https://www.psicomilenial.com/"
          },
        ],
      });
    }
    else if(langSelected === "ca")
    {
      this.setState(resumeData  = {
        "homeTitle": "Inici",
        "languages": "IDIOMES",
        "aboutTitle": "Sobre mi",
        "educationTitle": "Educació",
        "workTitle": "Treballs",
        "experienceTitle": "Experiència",
        "contactTitle": "Contacte",
        "name": "Daniel Soligó García",
        "role": "Full-Stack developer",
        "linkedinId":"daniel-soligó-garcía-8276aa120",
        "skypeid": "Your skypeid",
        "roleDescription": "experimentat especialment a backend, però creixent a frontend. Actualment estic treballant a Othic com a Full-Stack developer, les meves tasques son desenvolupar noves funcionalitats, realitzar mantenimient de múltiples aplicacions, fer analisis funcional i tècnic i migració d'aplicacions a tecnologies més actuals. Treballo amb les següents tecnologies: VB, ASP.NET, C#, .NET CORE, .NET Framework, SQL, React.",
        "socialLinks":[
            {
              "name":"linkedin",
              "url":"https://www.linkedin.com/in/daniel-soligó-garcía-8276aa120/",
              "className":"fa fa-linkedin"
            },
            {
              "name":"github",
              "url":"https://github.com/soligo94",
              "className":"fa fa-github"
            },
          ],
        "aboutmeTitle":"Sobre mí",
        "contactDetailsTitle":"Detalles de contacto",
        "aboutme":"Sóc una persona molt curiosa, m'agrada aprendre noves tecnologies i creixer com a desenvolupador de software. Parlo 4 idiomes: Espanyol, Català, Anglès y Noruèc. En el meu temps lliure m'agrada jugar a videojocs amb els meus amics, sortir amb ells i passejar per la natura.",
        "address":"soligodevelopment@outlook.es",
        "education":[
          {
            "UniversityName":"Centre d'estudis politècnics de Barcelona",
            "specialization":"Desarrollo de aplicaciones multiplataforma",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2017",
            "Achievements":"Grau superior de desenvolupament d'aplicacions multiplataforma, on vaig aprendre a programar i utilitzar llenguatges com: Java, HTML, CSS, C#, C i desenvolupament d'aplicacions mòbils amb Java."
          },
          {
            "UniversityName":"Taulé viñas",
            "specialization":"Gestió administrativa",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2013",
            "Achievements":"Grau mitjà en gestió administrativa."
          }
        ],
        "work":[
          {
            "CompanyName":"Ohtic",
            "specialization":"Full-Stack developer",
            "MonthOfLeaving":"Actualitat",
            "YearOfLeaving":"",
            "Achievements":"Desenvolupament Full-Stack amb ASP.NET, React, VB, C#, .NetCore, .NetFramework, SQL i TypeScript"
          },
          {
            "CompanyName":"Nunegal Consulting",
            "specialization":"C# developer",
            "MonthOfLeaving":"Mar",
            "YearOfLeaving":"2023",
            "Achievements":"Desenvolupament de backend con C#."
          },
          {
            "CompanyName":"Itequia",
            "specialization":"Full-Stack developer",
            "MonthOfLeaving":"Dec",
            "YearOfLeaving":"2022",
            "Achievements":"Desenvolupament full-stack en diversos proyectes amb les següents tecnologies: C#, ASP.NET, .NET Framework, .NET Core, React y WPF."
          },
          {
            "CompanyName":"ERNI",
            "specialization":"Software developer",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2022",
            "Achievements":"Desenvolupament i manteniment d'aplicacions en diversos proyectes amb les següents tecnologies: C#, Cache object script, WPF, Oracle, Java, SQL, JS."
          },
          {
            "CompanyName":"Consultia",
            "specialization":"Software developer",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2018",
            "Achievements":"Manteniment d'aplicacions en diversos proyectes amb les següents tecnologies: C#, WinForms, Oracle."
          },
        ],
        "skillsDescription":"Aquestes son les meves habilitats en les següents tecnologies:",
        "skills":[
          {
            "skillname":"CSharp"
          },
          {
            "skillname":"VB"
          },
          {
            "skillname":"NetCore"
          },
          {
            "skillname":"NetFramework"
          },
          {
            "skillname":"WPF"
          },
          {
            "skillname":"SQLServer"
          }
          ,
          {
            "skillname":"React"
          }
          ,
          {
            "skillname":"JavaScript"
          }
          ,
          {
            "skillname":"TypeScript"
          }
        ],
        "portfolioTitle":"Aqui tens alguns del meus projectes realitzats, també tens el meu github on pots veure més",
        "portfolio":[
          {
            "name":"La llar del vidre",
            "description":"Pàgina web",
            "imgurl":"images/portfolio/Llardelvidre.png"
          },
          {
            "name":"Psicomilenial",
            "description":"Pàgina web",
            "imgurl":"images/portfolio/psicomilenial.png"
          },
        ],
      });
    }
    else if(langSelected === "no")
    {
      this.setState(resumeData  = {
        "homeTitle": "HJEM",
        "languages": "SPRÅK",
        "aboutTitle": "Om meg",
        "workTitle": "ARBEID",
        "educationTitle": "Utdanning",
        "experienceTitle": "Efaring",
        "contactTitle": "Kontakt",
        "name": "Daniel Soligó García",
        "role": "Full-Stack utvikler",
        "linkedinId":"daniel-soligó-garcía-8276aa120",
        "skypeid": "Your skypeid",
        "roleDescription": "erfaren spesielt i backend, men vokser opp i frontend. Jeg jobber for tiden for Ohtic som Full-Stack-utvikler, mine oppgaver er å utvikle nye funksjoner og noe vedlikehold av flere applikasjoner, teknisk og funksjonell analyse og migrering av gamle applikasjoner til nye teknologier. Jeg jobber med følgende teknologier: VB, ASP.NET, C#, .NET CORE, .NET Framework, SQL, React.",
        "socialLinks":[
            {
              "name":"linkedin",
              "url":"https://www.linkedin.com/in/daniel-soligó-garcía-8276aa120/",
              "className":"fa fa-linkedin"
            },
            {
              "name":"github",
              "url":"https://github.com/soligo94",
              "className":"fa fa-github"
            },
          ],
        "aboutmeTitle":"Om meg",
        "contactDetailsTitle":"Kontaktinformasjon",
        "aboutme":"Jeg er en veldig nysgjerrig person, jeg liker å lære nye teknologier og vokse opp som programvareutvikler. Jeg snakker 4 språk: spansk, katalansk, engelsk og norsk. På fritiden liker jeg å spille videospill med vennene mine, henge med dem og gå rundt i naturen.",
        "address":"soligodevelopment@outlook.es",
        "education":[
          {
            "UniversityName":"Centre d'estudis politècnics de Barcelona",
            "specialization":"Utvikling av multiplattformapplikasjonsgrad",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2017",
            "Achievements":"Utvikling av multiplattformapplikasjonsgrad, hvor jeg lærte å programmere og bruke språk som: Java, HTML, CSS, C#, C og utvikling av mobilapplikasjoner med Java."
          },
          {
            "UniversityName":"Taulé viñas",
            "specialization":"Administrativ ledergrad",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2013",
            "Achievements":"Administrativ ledergrad."
          }
        ],
        "work":[
          {
            "CompanyName":"Ohtic",
            "specialization":"Full-Stack utvikler",
            "MonthOfLeaving":"For tiden",
            "YearOfLeaving":"",
            "Achievements":"Full-Stack utvikler, Jeg jobber med følgende teknologier: VB, ASP.NET, C#, .NET CORE, .NET Framework, SQL, React."
          },
          {
            "CompanyName":"Nunegal Consulting",
            "specialization":"C# utvikler",
            "MonthOfLeaving":"Mar",
            "YearOfLeaving":"2023",
            "Achievements":"Backend utvilker med C#."
          },
          {
            "CompanyName":"Itequia",
            "specialization":"Full-Stack utvikler",
            "MonthOfLeaving":"Des",
            "YearOfLeaving":"2022",
            "Achievements":"Full-stack utvilker av flere prosjekter med følgende teknologier: C#, ASP.NET, .NET Framework, .NET Core, React og WPF."
          },
          {
            "CompanyName":"ERNI",
            "specialization":"Programvare utvikler",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2022",
            "Achievements":"Utvilker og vedlikehold av applikasjoner av flere prosjekter med følgende teknologier: C#, Cache object script, WPF, Oracle, Java, SQL, JS."
          },
          {
            "CompanyName":"Consultia",
            "specialization":"Programvare utvikler",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2018",
            "Achievements":"Vedlikehold av applikasjoner av flere prosjekter med følgende teknologier: C#, WinForms, Oracle."
          },
        ],
        "skillsDescription":"Det er mine ferdigheter på følgende programmeringsspråk:",
        "skills":[
          {
            "skillname":"CSharp"
          },
          {
            "skillname":"VB"
          },
          {
            "skillname":"NetCore"
          },
          {
            "skillname":"NetFramework"
          },
          {
            "skillname":"WPF"
          },
          {
            "skillname":"SQLServer"
          }
          ,
          {
            "skillname":"React"
          }
          ,
          {
            "skillname":"JavaScript"
          }
          ,
          {
            "skillname":"TypeScript"
          }
        ],
        "portfolioTitle":"Det er noen prosjekter laget av meg selv, også du har github for å se mer:",
        "portfolio":[
          {
            "name":"La llar del vidre",
            "description":"Nettside",
            "imgurl":"images/portfolio/Llardelvidre.png"
          },
          {
            "name":"Psicomilenial",
            "description":"Nettside",
            "imgurl":"images/portfolio/psicomilenial.png"
          },
        ],
      });
    }
    else if(langSelected === "en"){
      this.setState(resumeData  = {
        "homeTitle": "Home",
        "languages": "LANGUAGES",
        "aboutTitle": "About me",
        "workTitle": "Works",
        "educationTitle": "Education",
        "experienceTitle": "Experience",
        "contactTitle": "Contacto",
        "name": "Daniel Soligó García",
        "role": "Full-Stack developer",
        "linkedinId":"daniel-soligó-garcía-8276aa120",
        "skypeid": "Your skypeid",
        "roleDescription": "experienced specially in backend, but growing up in frontend. I'm currently working for Ohtic as Full-Stack developer, my duties are develop new features and some maintenance of several applications, technical and functional analysis and migration of old applications to new technologies. I'm working with the following technologies: VB, ASP.NET, C#, .NET CORE, .NET Framework, SQL, React.",
        "socialLinks":[
            {
              "name":"linkedin",
              "url":"https://www.linkedin.com/in/daniel-soligó-garcía-8276aa120/",
              "className":"fa fa-linkedin"
            },
            {
              "name":"github",
              "url":"https://github.com/soligo94",
              "className":"fa fa-github"
            },
          ],
        "aboutmeTitle":"About me",
        "contactDetailsTitle":"Contact Details",
        "aboutme":"I'm a very curious person, I like learn new technologies and grow up as software developer. I speak 4 languages: Spanish, Catalan, English and Norwegian. In my free time I like to play videogames with my friends, hang out with them and walking around the nature.",
        "address":"soligodevelopment@outlook.es",
        "education":[
          {
            "UniversityName":"Centre d'estudis politècnics de Barcelona",
            "specialization":"Development of multiplatform applications degree",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2017",
            "Achievements":"Development of multiplatform applications degree, where I learnt to programming and use languages like: Java, HTML, CSS, C#, C and app mobile development with Java."
          },
          {
            "UniversityName":"Taulé viñas",
            "specialization":"Administrative management degree",
            "MonthOfPassing":"Jun",
            "YearOfPassing":"2013",
            "Achievements":"Administrative management degree"
          }
        ],
        "work":[
          {
            "CompanyName":"Ohtic",
            "specialization":"Full-Stack developer",
            "MonthOfLeaving":"Current job",
            "YearOfLeaving":"",
            "Achievements":"Full-Stack development With ASP.NET, React, VB, C#, .NetCore, .NetFramework, SQL and TypeScript"
          },
          {
            "CompanyName":"Nunegal Consulting",
            "specialization":"C# developer",
            "MonthOfLeaving":"Mar",
            "YearOfLeaving":"2023",
            "Achievements":"C# Backend developer."
          },
          {
            "CompanyName":"Itequia",
            "specialization":"Full-Stack developer",
            "MonthOfLeaving":"Dec",
            "YearOfLeaving":"2022",
            "Achievements":"Full-Stack developer in several projects with the following languages: C#, ASP.NET, .NET Framework, .NET Core, React and WPF."
          },
          {
            "CompanyName":"ERNI",
            "specialization":"Software developer",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2022",
            "Achievements":"Develop and manteinance of applications in several projects with the following languages: C#, Cache object script, WPF, Oracle, Java, SQL, JS."
          },
          {
            "CompanyName":"Consultia",
            "specialization":"Software developer",
            "MonthOfLeaving":"Feb",
            "YearOfLeaving":"2018",
            "Achievements":"Manteinance of several applications in several projects with the following technologies: C#, WinForms, Oracle."
          },
        ],
        "skillsDescription":"There are my skills on the following languages:",
        "skills":[
          {
            "skillname":"CSharp"
          },
          {
            "skillname":"VB"
          },
          {
            "skillname":"NetCore"
          },
          {
            "skillname":"NetFramework"
          },
          {
            "skillname":"WPF"
          },
          {
            "skillname":"SQLServer"
          }
          ,
          {
            "skillname":"React"
          }
          ,
          {
            "skillname":"JavaScript"
          }
          ,
          {
            "skillname":"TypeScript"
          }
        ],
        "portfolioTitle":"There are some projects made by myself, also you have github to see more",
        "portfolio":[
          {
            "name":"La llar del vidre",
            "description":"Web page",
            "imgurl":"images/portfolio/Llardelvidre.png"
          },
          {
            "name":"Psicomilenial",
            "description":"Web page",
            "imgurl":"images/portfolio/psicomilenial.png"
          },
        ],
      });
    }
  }
  
  render() {
    return (
      <div className="App">
        <Header resumeData={resumeData} changeLang={this.state.lang} onLangChange={this.handleCallback}/>
        <About resumeData={resumeData}/>
        <Resume resumeData={resumeData}/>
        <Portfolio resumeData={resumeData}/>
        <Footer resumeData={resumeData}/>
      </div>
    );
  }
}

export default App;