import React, { Component } from 'react';
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div id="rowportfolio" className="row">
        <div className="twelve columns collapsed">
          <h1>{resumeData.portfolioTitle}</h1>
          <div id="portfolio-wrapper" className="bgrid-halves .s-bgrid-halves cf">
          <li style={{listStyle: 'none'}}>
          {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(


                      <div key={item.name} className="columns portfolio-item">
                        <div id={item.name} className="item-wrap"></div>
                        <a target="_blank" rel="noreferrer" aria-label={`${item.name}`} href={item.url}>
                          <img src={`${item.imgurl}`} aria-label={`${item.name}`} className="item-img"/>
                       </a>  
                        <p style={{textAlign: 'center', color:'white'}}>{item.name} - {item.description}</p>
                      </div>


                   

              )
            })
          }
           </li>
          </div>
        </div>
      </div>
  </section>
        );
  }
}